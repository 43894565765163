<template>
  <div id="app">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Alibaba-PuHuiTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 42px;
}
body,h1,h2,ol,ul,dl,dd,p,textarea,input,select,option,form {
  margin:0; padding:0;
  font-size: 14px;
}
ul,ol {
  list-style-type:none;
}
</style>
