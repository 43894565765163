// axios 配置
import axios from 'axios'
// import store from './store'
// import router from './router'

//创建 axios 实例
let instance = axios.create({
  timeout: 90000, // 请求超过5秒即超时返回错误
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
})


export default {
  getHostUrl() {
    return "https://bnbdaoserver.bnbdao.workers.dev/api/claim/";//"http://127.0.0.1:10011";//
  },
  getMainHostUrl() {
    return "https://bnbdaoserver.bnbdao.workers.dev/api/claim/";//"http://127.0.0.1:10010";//
  },
  loadTokenResult(wallet) {
    return instance.get(this.getHostUrl()+wallet);
  },
  // 发送验证码
  userVerify (data) {
    return instance.post('/api/verify', data)
  },
  // 注册
  userRegister (data) {
    return instance.post('/api/register', data)
  },
  // 登录
  userLogin (data) {
    return instance.post('/api/login', data)
  },
  // 获取用户列表
  getAllUser () {
    return instance.get('/api/alluser')
  },
  // 删除用户
  delUser (data) {
    return instance.post('/api/deluser', data)
  },

  getUserInfoValues(owner) {
    return instance.get(this.getHostUrl()+"/userInfoValues?owner="+owner);
  },
  getUserBeInviters(owner) {
    return instance.get(this.getHostUrl()+"/userBeInviters?owner="+owner);
  },
  getUserWithdrawListValue(owner) {
    return instance.get(this.getHostUrl()+"/userWithdrawList?owner="+owner);
  },
  getUserDownlines(owner) {
    return instance.get(this.getHostUrl()+"/downLines?owner="+owner);
  },
  getUserDepositListValue(owner) {
    return instance.get(this.getHostUrl()+"/userDepositList?owner="+owner);
  },
  getUserBeInviterLinesValue(owner) {
    return instance.post(this.getMainHostUrl()+"/beInviterLines", {owner: owner});
  },
  getUserRewardDepositValue(owners) {
    return instance.post(this.getHostUrl()+"/rewardDeposit", {owners: owners});
  }
}
