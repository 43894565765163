<template>
  <div class="container">
    <div class="name-row name-flex-space-between">
      <img src="../assets/leftLogo.png" class="logo">
      <div class="name-flex-value"></div>
      <img @click="toTelegram" src="../assets/telegram.png" class="icon">
      <img @click="toTwitter" src="../assets/twitter.png" class="icon">
      <img @click="toDextools" src="../assets/dextools.png" class="icon">
      <img @click="toBSC" src="../assets/bsc.png" class="icon">
    </div>

    <div>
      <img src="../assets/logo.png" class="log-big">
    </div>
    <p class="title">
      INTRODUCING $BNBDAO
    </p>

    <div class="name-column name-column-topCenter" style="margin-bottom: 30px">
      <p class="font-size-21" style="font-weight: bold;color: #1E2329;max-width: 681px;">
        A next generation token catered to rewarding every single user who holds a Binacne NFT.
      </p>
    </div>
    <div class="step">
      <div class="name-row step-row">
        <div class="name-row name-flex-center step-item-1">
          1
        </div>
        <div style="font-size: 27px;font-weight: bold;color: #1E2329; margin-left: 9px">
          Connect Wallet
        </div>
      </div>
      <div class="name-row step-row">
        <div class="name-row name-flex-center step-item-other" :style="{background: (step < 1 ? '#ECECEC' : '')}">
          2
        </div>
        <div style="font-size: 27px;font-weight: bold;color: #1E2329; margin-left: 9px">
          Calculate Earnings
        </div>
      </div>
      <div class="name-row step-row">
        <div class="name-row name-flex-center step-item-other" :style="{background: (step < 2 ? '#ECECEC' : '')}">
          3
        </div>
        <div style="font-size: 27px;font-weight: bold;color: #1E2329; margin-left: 9px">
          Claim Rewards
        </div>
      </div>
    </div>
    <div class="name-column name-flex-center" style="margin-top: 46px; max-width: 1150px;height: 700px;background: #EFEFEF;border: 4px solid #1E2329;border-radius: 10px;">
<!--      step 0-->
      <div v-if="step==0" class="name-column name-flex-center">
        <div style="font-size: 40px;font-weight: bold;color: #1E2329;">
          Connect MetaMask:
        </div>
        <div style="font-size: 21px;font-weight: bold;color: #1E2329; max-width: 671px; margin-top: 43px">
          We use Binance NFT to pull transaction details from the Binance blockchain in order to calculate your earnings for
          <span style="color: #F0B90B">$BNBDAO</span>
        </div>
        <div style="height: 56px"></div>
        <div @click="connect" class="name-row name-flex-center" style="min-width: 300px;height: 64px;background: white;border: 4px solid #F0B90B;border-radius: 100px;">
          <img src="../assets/metamask.png" style="width: 33px; height: auto; margin-right: 13px">
          <div style="width: 195px;font-size: 21px;font-weight: bold;color: #1E2329;">
            Connect MetaMask
          </div>
        </div>
      </div>
      <div v-if="step==1" class="name-column name-flex-center">
        <div style="font-size: 40px;font-weight: bold;color: #1E2329;">
          Counting rewards
        </div>
        <div style="font-size: 21px;font-weight: bold;color: #1E2329; max-width: 671px; margin-top: 43px">
          <span style="color: #F0B90B"> loading... </span>
        </div>
      </div>
      <div v-if="step==2" class="name-column name-flex-center">
        <div style="font-size: 40px;font-weight: bold;color: #1E2329;">
          Reward is
        </div>
        <div style="font-size: 21px;font-weight: bold;color: #1E2329; max-width: 671px; margin-top: 43px">
          <span style="color: #F0B90B"> {{tokenNum}} </span>
          $BNBDAO
        </div>
        <div style="height: 56px"></div>
        <div @click="claimTap" class="name-row name-flex-center" style="width: 300px;height: 64px;background: white;border: 4px solid #F0B90B; border-radius: 100px;">
          <div style="width: 195px;font-size: 21px;font-weight: bold;color: #1E2329;">
            Claim
          </div>
        </div>

      </div>
    </div>
    <div style="height: 121px"></div>
<!--    About-->
    <div class="name-row">
      <img src="../assets/about.png" class="tips-logo">
    </div>
    <div style="height: 34px"/>
    <div class="font-size-24" style="font-weight: bold;color: #1E2329; text-align: left">
      <span style="color: #F0B90B">$BNBDAO</span>
      is a token designed to reward the members of the Binance blockchain. Whether that’s sending and receiving BNB, trading tokens on Dapp, buying and selling NFTs, or whatever adventure you embark on in the blockchain, we’ve got you covered.
       <div style="margin-top: 20px">
         Anyone who has a Binance NFT is eligible for this token claim — not only will we reward you for your blockchain activity, but we will also take into account how much gas you’ve leveraged throughout your journey.
      </div>
    </div>
    <div style="height: 115px"></div>
<!--    Tokens-->
    <div class="chart-container">
      <div class="name-column name-column-topLeft">
        <img src="../assets/token.png" class="tips-logo">
        <div style="height: 35px"/>
        <div class="font-size-24" style="font-weight: bold;color: #1E2329; text-align: left">
          There will be a total of
          <span style="color: #F0B90B">100,000,000,000,000</span>
          tokens:
        </div>
        <div style="height: 25px"/>
        <div class="name-row">
          <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
            <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
          </div>
          <div class="font-size-21" style="font-weight: bold;color: #1E2329; text-align: left">
            50% will be allocated towards
            <span style="color: #F0B90B">airdrops</span>
            for Binance NFT users
          </div>
        </div>
        <div style="height: 25px"/>
        <div class="name-row">
          <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
            <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
          </div>
          <div class="font-size-21" style="font-weight: bold;color: #1E2329; text-align: left">
            <span style="color: #F0B90B">30%</span>
             will be allocated towards the
            <span style="color: #F0B90B">DAO</span>
          </div>
        </div>
        <div style="height: 25px"/>
        <div class="name-row">
          <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
            <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
          </div>
          <div class="font-size-21" style="font-weight: bold;color: #1E2329; text-align: left">
            <span style="color: #F0B90B">20%</span>
             will be allocated towards
            <span style="color: #F0B90B">LP incentives</span>
            (see below)
          </div>
        </div>
      </div>
      <img src="../assets/chart.png" class="chart">
    </div>
<!--    BNBDAO-->
    <div class="name-column name-column-topLeft">
      <div class="name-row">
        <img src="../assets/bnbdao.png" class="tips-logo">
      </div>
      <div style="height: 35px"/>
      <div class="font-size-24" style="font-weight: bold;color: #1E2329; text-align: left">
        30% of <span style="color: #F0B90B">$BNBDAO</span> tokens will be allocated to our DAO:
      </div>
      <div style="height: 25px"/>
      <div class="name-row">
        <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
          <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
        </div>
        <div class="font-size-21" style="font-weight: bold;color: #1E2329; text-align: left">
          Rug Relief: whether that’s shitcoins, NFTs, or straight-up scams, we are here for the community
        </div>
      </div>
      <div style="height: 25px"/>
      <div class="name-row name-row-centerLeft">
        <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
          <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
        </div>
        <div class="font-size-21" style="font-weight: bold;color: #1E2329; text-align: left">
          Supporting up and coming devs to let their creations flourish on Web3
        </div>
      </div>
      <div style="height: 25px"/>
      <div class="name-row name-row-centerLeft">
        <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
          <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
        </div>
        <div class="font-size-21" style="font-weight: bold;color: #1E2329; text-align: left">
          Support NFT/Token communities
        </div>
      </div>
      <div style="height: 25px"/>
      <div class="name-row name-row-centerLeft">
        <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
          <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
        </div>
        <div class="font-size-21" style="font-weight: bold;color: #1E2329;">
          <span style="color: #F0B90B">$BNBDAO</span>
           ecosystem
        </div>
      </div>
    </div>
    <div style="height: 90px"/>
<!--    AirDrops-->
    <div class="name-column name-column-topLeft">
      <div class="name-row">
        <img src="../assets/airdrops.png" class="tips-logo">
      </div>
      <div style="height: 35px"/>
      <div class="font-size-24" style="font-weight: bold;color: #1E2329; text-align: left">
        50% of all
        <span style="color: #F0B90B">$BNBDAO</span>
        tokens will be available to ALL Binance  users dating back to the day the wallet was created, across the wallet’s entire lifespan

        <div style="height: 25px"/>
        <div>
          The distributions will be based on the amount of BNB you’ve sent and received, which includes swaps, buys, sells on any dapp or Binance NFT marketplace (75%), the amount of BNB you’ve spent on gas (15%), and the number of BNB transactions you’ve done (10%)
       </div>
        <div style="height: 25px"/>
        <div>
          The collection time will be collected one week after the LP is officially online (The LP online time is subject to the official Twitter announcement)，Users will have until May 30th, 2022 to claim their tokens, after which any leftover tokens will be rolled into the BNBDAO treasury
        </div>
      </div>

    </div>
    <div style="height: 90px"/>
<!--    LP-->
    <div class="name-column name-column-topLeft">
      <div class="name-row">
        <img src="../assets/lp.png" class="tips-logo">
      </div>
      <div style="height: 35px"/>
      <div class="font-size-24" style="font-weight: bold;color: #1E2329; text-align: left">

        For LP Incentives, there are
        <span style="color: #F0B90B">20%</span>
        tokens set aside (see graph) for people who add Liquidity to the coin via Pancakeswap. In exactly one week , we will take a snapshot of the people that have added liquidity (and how much they added), and will airdrop all of the tokens proportionally.

        <div style="height: 25px"/>
        <div>
          After a week, tokens will be distributed to LP providers on a
          <span style="color: #F0B90B">vesting schedule</span>
          as follows:
        </div>
        <div style="height: 25px"/>
        <div class="name-row">
          <div class="name-column name-flex-center" style="width: 10px; height: 30px; margin-right: 16px">
            <div style="width: 10px; height: 10px; transform: rotate(45deg); background-color: #1E2329"/>
          </div>
          <div class="font-size-21" style="font-weight: bold;color: #1E2329;">
            Each LP provider will be distributed 10% of entitled tokens on a 10 month schedule. This incentivizes LP providers to hold tokens and not dump once tokens are released to them.          </div>
        </div>
        <div style="height: 25px"/>
        <div>
          Staking is also planned in the roadmap in order to solidify LP providers holdings and for them to passively increase their holdings        </div>
      </div>

    </div>
    <div style="height: 109px"/>
    <div class="name-column name-column-topLeft">
      <div class="name-row">
        <img src="../assets/roadmap.png" class="tips-logo">
      </div>
      <div class="roadmap-detail-pc" style="height: 94px"/>
      <div class="name-row roadmap-detail-pc">
        <img src="../assets/roadmap-pc.png" style="width: 100%; height: auto;">
      </div>
      <div class="roadmap-detail-mb" style="height: 67px"/>
      <div class="name-row name-row-centerRight roadmap-detail-mb">
        <img src="../assets/roadmap-mb.png" style="width: 100%; height: auto;">
      </div>
    </div>
    <div style="height: 196px"/>
    <div class="name-row name-flex-space-between">
      <div class="font-size-20" style="font-weight: bold;color: #1E2329;">
        Copyright © $BNBDAO 2021
      </div>
      <div class="font-size-20" style="font-weight: bold;color: #1E2329;">
        All Rights Reserved
      </div>
    </div>
    <div style="height: 164px"/>
  </div>
</template>

<script>
import Web3 from "web3";
import axios from "@/axios";
import BigNumber from 'bignumber.js'
import tokenAbi from "@/assets/abi/TokenAbi";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  mounted() {
    if (window.ethereum) {
      let self = this;
      window.ethereum.on('accountsChanged', function (accounts) {
        if (accounts[0] != self.wallet) {
          self.wallet = accounts[0];
          // self.window.reload();
          self.step = 1;
        }
      });
    }
  },
  data() {
    return {
      web3: null,
      wallet: null,
      step: 0,
      tokenResult: null,
      tokenNum: 0,
      tokenAddr: "0x7E4AF2E2b5CC5Fb8472d92E96472F2994AD31c56",
      tokenContract: null,
    }
  },
  methods: {
    toTelegram() {
      window.open("https://t.me/BNB_DAO")
    },
    toTwitter() {
      window.open("https://twitter.com/BNBDAO_")
    },
    toDextools() {
      // window.open("https://www.dextools.io/app/bsc/pair-explorer/0xe2e0880461b585b079c74629c65e5b63adc894b7")
    },
    toBSC() {
      window.open("https://bscscan.com/address/"+this.tokenAddr);
    },
    async claimTap() {
      if (!this.tokenContract) {
        this.tokenContract = await new this.web3.eth.Contract(tokenAbi, this.tokenAddr);
      }
      let amount = new BigNumber(this.tokenResult.amount).toFixed(0);
      console.log(amount);
      console.log(this.tokenResult.sign);
      let result = await this.tokenContract.methods.claim(amount, this.tokenResult.sign).send({
        from: this.wallet
      });
      if (result.status == true) {
        console.log("claim success");
      }

    },
    async connect() {
      if (this.wallet) {
        return;
      }
      if (window.ethereum) {
        try {
          this.web3 = new Web3(window.ethereum);
          let self = this;
          let web3 = this.web3;
          let chainId = await web3.eth.getChainId();
          if (chainId != 56) {
            let ethereum = window.ethereum;
            const data = [{
              chainId: '0x38',
              chainName: 'Binance Smart Chain',
              nativeCurrency:
                  {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                  },
              rpcUrls: ['https://bsc-dataseed.binance.org/'],
              blockExplorerUrls: ['https://bscscan.com/'],
            }]
            /* eslint-disable */
            const tx = await ethereum.request({method: 'wallet_addEthereumChain', params:data}).catch()
            if (tx) {
              console.log(tx)
              this.connect().then();
            }
            return;
          }
          this.web3.eth.requestAccounts().then(function (wallets) {
            self.wallet = wallets[0];
            self.loadTokenResult();
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
    async loadTokenResult() {
      try {
        this.step = 1;
        console.log()
        let result = localStorage.getItem("bnb"+this.wallet+"daoV3");
        if (result) {
          console.log("cache result");
          console.log(result)
          this.tokenResult = JSON.parse(result);
          console.log(this.tokenResult)
          let amount = new BigNumber(this.tokenResult.amount).div(1e18).toFixed(2);
          this.tokenNum = amount;
          this.step = 2;
          return;
        }
        result = await axios.loadTokenResult(this.wallet);
        if (result.data.code == 0) {
          this.tokenResult = result.data.data;
          let amount = new BigNumber(this.tokenResult.amount).div(1e18).toFixed(2);
          this.tokenNum = amount;
          localStorage.setItem(this.wallet, JSON.stringify(this.tokenResult))
        }
        this.step = 2;
      } catch (e) {
        console.log(e);
        let self = this;
        setTimeout(function () {
          self.loadTokenResult();
        }, 1000);
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.step-unabled {
  background: #ECECEC;
}
@media all and (max-width: 769px) {
  .roadmap-detail-pc {
    display: none;
  }
  .container {
    width: 90%;
    margin: auto;
  }
  .logo {
    width: auto;
    height: 30px
  }
  .icon {
    height: 30px;
    width: auto;
    margin-left: 5px
  }
  .log-big {
    height: 200px;
    width: auto;
    margin-top: 55px
  }
  .title {
    margin-top: 37px;
    font-size: 40px;
    font-weight: bold;
    color: #1E2329
  }
  .step {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .step-row {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 20px;
  }
  .step-item-1 {
    width: 40px;height: 40px;background: linear-gradient(0deg, #EBAE08 0%, #F7CF2C 100%);border-radius: 50%;
    font-size: 29px;font-weight: bold;color: #1E2329;

  }
  .step-item-other {
    width: 40px;height: 40px;background: linear-gradient(0deg, #EBAE08 0%, #F7CF2C 100%);border-radius: 50%;
    font-size: 29px;font-weight: bold;color: #1E2329;
  }
  .tips-logo {
    height: 28px; width: auto
  }
  .font-size-24 {
    font-size: 18px;
  }
  .font-size-21 {
    font-size: 16px;
  }
  font-size-20 {
    font-size: 12px;
  }
  .chart-container {

  }
  .chart {
    margin-top: 23px;
    margin-bottom: 60px;
    height: 280px;
    width: auto
  }
}
@media (min-width: 1201px) {
  .roadmap-detail-mb {
    display: none;
  }
  .container {
    /*margin-left: 385px;*/
    /*margin-right: 385px;*/
    margin: auto;
    max-width: 1150px;
  }
  .logo {
    width: auto;
    height: 44px
  }
  .icon {
    height: 50px;
    width: auto;
    margin-left: 36px
  }
  .log-big {
    height: 312px;
    width: auto;
    margin-top: 55px
  }
  .title {
    margin-top: 37px;
    font-size: 64px;
    font-weight: bold;
    color: #1E2329
  }
  .step {
    display: flex;
    flex-direction: row;
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    justify-content: center;
    align-items: flex-start;
  }
  .step-row {}
  .step-item-1 {
    width: 40px;height: 40px;background: linear-gradient(0deg, #EBAE08 0%, #F7CF2C 100%);border-radius: 50%;
    font-size: 29px;font-weight: bold;color: #1E2329;
  }
  .step-item-other {
    width: 40px;height: 40px;background: linear-gradient(0deg, #EBAE08 0%, #F7CF2C 100%);border-radius: 50%;
    font-size: 29px;font-weight: bold;color: #1E2329; margin-left: 9px
  }
  .tips-logo {
    height: 48px; width: auto
  }
  .font-size-24 {
    font-size: 24px;
  }
  .font-size-21 {
    font-size: 21px;
  }
  font-size-20 {
    font-size: 20px;
  }
  .chart-container {
    display: flex;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .chart {
    margin-top: 13px; height: 480px; width: auto
  }
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
